.main {
    height: 100vh;
}

.home-bg {
    padding-top: 90px;
    height: 100%;
    min-height: 100vh;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body > iframe {
    pointer-events: none;
}

