body.light {
    --white:#fff;
    --black:#000;
    --red:#f85b00;
    --gray:#636363;
    --home-bg:#f7f7f7;
    --tag-bg:#272c33;
    --dark-gray:#17191e;
    --info-body:#24252b;
    --green-txt:#03c768;
    --red-txt:#ff2b2b;
    --blue:#00204C;
    --kind-yellow: #f2f0ec;
    --light-gray:#f4f6fc;
    --address-tag:#4d4e50;

    --black-white:#000;
    --blue-white:#00204C;
    --white-black:#fff;
    --gray-lightgray:#636363;
    --dark-light:#fff;
    --white-darkgray:#fff;
    --lightgray-darkgray:#f4f6fc;

    /* darkmode */
    --copy-paste:#817f7f86;
    

    --headerbtn-bg:#363840;
    --searchcontainer-bg:#1a1c21;
    --purple-shadow:#e9d4ed;

    
}