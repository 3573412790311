.side_bar {
    position: fixed;
    right: 0;
    top: 0;
    background: var(--red);
    width: 75px;
    height:100vh;
    padding: 0 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidenav-ul {
    list-style: none;
}

.sidenav-ul li:first-child {
    margin-top: .9rem;
}

.sidenav-ul a {
    text-decoration: none;
}

.sidenav-links {
    margin-top: 1.2rem;
}

.sidenav-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidenav-text{
    font-size: 9px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--white);
}

.sidenav-links:first-child .sidenav-img {
    height: 30px;
}

.sidenav-img {
    height: 35px;
}

.daily-winner a {
    line-height: 1;
}
.daily-winner img {
    border-radius: 8px;
}

.social-side-links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    color: var(--white);
}

.social-side-links a{
    color: var(--white);
    transition: color .2s;
}

.social-side-links a:hover{
    color: var(--black);
}

.side-nav-door {
    font-size: 20px;
    color: var(--grayish-text);
}

.sidenav-img.small {
    height: 28px;
}

 
@media screen and (max-width:992px) {
    .side_bar {
        display: none;
    }
}