body.dark {
    --white:#fff;
    --black:#000;
    --red:#ff7300;
    --gray:#636363;
    --home-bg:#17171a;
    --tag-bg:#272c33;
    --dark-gray:#17191e;
    --info-body:#24252b;
    --green-txt:#22e386;
    --red-txt:#ff2b2b;
    --blue:#00204C;
    --kind-yellow: #f2f0ec;
    --light-gray:#f4f6fc;
    --address-tag:#fff;

    --black-white:#fff;
    --blue-white:#fff;
    --white-black:#000;
    --gray-lightgray:#eee;
    --dark-light:#e6e1e1;
    --white-darkgray:#0f0e0f;
    --lightgray-darkgray:#272c33;

    /* darkmode */
    --copy-paste:#817f7f86;
    

    --headerbtn-bg:#363840;
    --searchcontainer-bg:#0f0e0f;
    --purple-shadow:#e9d4ed;

    
}