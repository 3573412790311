.chart-table-liq td:nth-child(1) {
    padding-left: 17px;
  }
  @media (max-width: 768px) {
    .chart-table-liq td:nth-child(1) {
      padding-left: 0px;
    }
  }
  
  .chart-table-liq td:nth-child(2) {
    padding-left: 220px;
  }
  @media (max-width: 768px) {
    .chart-table-liq td:nth-child(2) {
      padding-left: 330px;
    }
  }
  
  .chart-table-liq td:nth-child(3) {
    padding-left: 185px;
  }
  @media (max-width: 768px) {
    .chart-table-liq td:nth-child(3) {
      padding-left: 300px;
    }
  }

  .chart-table-liquidity th:nth-child(1) {
    padding-left: 180px;
  }
  @media (max-width: 768px) {
    .chart-table-liquidity th:nth-child(1) {
      padding-left: 1px;
    }
  }
  
  .chart-table-liquidity th:nth-child(2) {
    padding-left: 90px;
  }
  @media (max-width: 768px) {
    .chart-table-liquidity th:nth-child(2) {
      padding-left: 360px;
    }
  }
  
  .chart-table-liquidity th:nth-child(3) {
    padding-left: 200px;
  }
  @media (max-width: 768px) {
    .chart-table-liquidity th:nth-child(3) {
      padding-left: 310px;
    }
  }
  .ath-value {
    position: relative;
    left: -288px;
    top: 52px;
    font-weight: bold;
    color: rgb(35, 151, 0);
    overflow: visible;
    font-size: 13px;
  }
  @media (max-width: 768px) {
    .ath-value {
      position: relative;
      left: -120px;
      top: 52px;
      font-weight: bold;
      color: rgb(35, 151, 0);
      overflow: visible;
      font-size: 13px;
    }
  }
  .price-value {
    position: relative;
    left: 1px;
    top: 0px;
    font-weight: bold;
    color: rgb(253, 153, 2);
  }
