.chart-bg {
    padding-top: 60px;
    width: calc(100% - 75px);
    background: var(--home-bg);
    height: 100vh;
}

.token-info-container {
    background: var(--searchcontainer-bg);
    height: auto;
    padding: 30px 40px;
    border-radius: 6px;
    box-shadow: 2px 2px 5px var(--searchcontainer-bg);
    margin-bottom: 15px;
    margin-top: 12px;
}

.first-row,
.second-row {
    display: flex; 
    align-items: center;
    justify-content: space-between;
}

.first-row {
    margin-bottom: 30px;
}


/* Chart Title */

.title-container {
    display: flex;
    align-items: center;
    margin-right: 40px;
}
/*LP text color*/
.bright-text {
    color: #ffa600;
  }

.token-img {
    height: 60px;
    margin-right: 20px;
    border-radius: 5px;
}
img.token-logo {
    width: 70px;
    height: 70px;
    margin-top: -10px;
}

.token-title {
    color: var(--red);
    font-size: 35px;
    max-width: 300px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: .02em;
    margin-bottom: 0;
}

/* chart price movement */

.price-movement {
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.token-price {
    font-family: 'Montserrat', sans-serif;
    font-size: 35px;
    max-width: 300px;
    font-weight: 500;
    color: var(--white);
    margin-bottom: 0;
    margin-right: 20px;
}

.movement {
    display: flex;
    align-items: center;
    justify-content: center;
    
    border: none;
    border-radius: 8px;
    padding: 2px 10px;
    min-width: 60px;
}
.movement.red{
    background: var(--red);
}
.movement.green{
    background: var(--green-txt);
}

.movement svg {
    height: 10px;
    padding-bottom: 2px;
    color: var(--white);
}

.movement span{
    color: var(--white);
    font-size: 10px;
    font-weight: 500;
}

/* chart tag */

.token-address-tag {
    display: flex;
    align-items: center;
    background: var(--tag-bg);
    padding: 4px 12px;
    border-radius: 9px;
    border: 1px solid var(--tag-bg);
    /* margin-right: 1.5rem; */
}

.token-address-tag .bsc{
    display: inline-block;
    height: 12px;
    margin-right: 5px;
}

.token-address-tag span{
    font-size: 10px;
    font-weight: 600;
    color: var(--white);
}

.token-address-tag svg{
    cursor: pointer;
    color: var(--copy-paste);
    font-size: 12px;
    transition: color .2s;
}

.token-address-tag svg:hover{
    color: var(--red);
}

.uppercase {
    text-transform: uppercase;
}

/* chart btns */

.chtr-list {
    display: flex;
    align-items: center;
}
@media (max-width: 768px) {
    .chtr-list {
        display: flex;
    }
}

.chart-btns-container {
    display: flex;
    align-items: center;
}

.chart-btns {
    border: none;
    background: var(--tag-bg);
    border-radius: 9px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    margin-right: 20px;
}

.chart-btns:last-of-type {
    margin-right: 0px;
}

.chart-btns:hover {
    text-decoration: none;
}
@media (max-width: 768px) {
    .chart-btns:hover {
        text-decoration: none;
    }
}

.chart-btns span{
    font-size: 10px;
    font-weight: 600;
    color: var(--white);
    padding: 4px 12px 5px;
}
@media (max-width: 768px) {
    .chart-btns span{
        padding: 4px 8px 5px;
    }
}

/* chart chtr */
.chart-chtr {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
@media (max-width: 768px) {
    .chart-chtr {
        margin-right: 10px;
    }
}

.chart-chtr:last-of-type {
    margin-right: 0px;
}
@media (max-width: 768px) {
    .chart-chtr:last-of-type {
        margin-right: 10px;
    }
}

.chart-chtr:hover {
    text-decoration: none;
}
@media (max-width: 768px) {
    .chart-chtr:hover {
        text-decoration: none;
    }
}

.chart-chtr span {
    font-size: 10px;
    font-weight: 600;
    color: var(--white);
}
@media (max-width: 768px) {
    .chart-chtr span {
        font-size: 9px;
    }
}

.bsc-img {
    height: 20px;
    background: var(--white);
    border-radius: 50%;
    margin-right: 6px;
}

.chart-container {
    width: 100%;
    height: 527px;
    background: var(--searchcontainer-bg);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px var(--searchcontainer-bg);
}

.charting {
    width: 100%;
    height: 100%;
}

#chart {
    width: 98%;
    height: 98%;
}

/* chart info headers btn */

.chart-info-container {
    background: #1a1c21;
    height: 530px;
    border-radius: 6px;
    box-shadow: 2px 2px 5px var(--searchcontainer-bg);
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
}

.chart-info-header {
    height: 25px;
    background: #1a1c21;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: sticky;
    padding-top: 10px;
    z-index: 10;
}

.headers-info-btn {
    border: none;
    background: var(--tag-bg);
    border-radius: 9px;
    display: flex;
    justify-content: center;
}

.headers-info-btn span{
    color: var(--white);
    font-size: 10px;
    font-weight: 600;
    padding: 6px 18px;
}

/* chart info body */

.chart-info-body {
    padding: 10px ;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.chart-table-info.liquidity th:first-child,
.chart-table-info.liquidity td:first-child {
    max-width: 30px;
    width: 30px;
}
.token-tx-text {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #ff7b00;
}
.last-row {
    padding-bottom: 50px;
}

.chart-table-info {
    width: 100%;
    table-layout: fixed;
}

.chart-table-header {
    width: 100%;
}

.chart-table-header th{
    color: var(--white);
    font-size: 11px;
    text-align: center;
}

.chart-table-header th:last-child{
    min-width: 45px;
}
@media (max-width: 768px) {
    .chart-table-header th:last-child{
        min-width: 35px;
    }
}

.chart-table-body {
    width: 100%;
    padding: 15px 0px;
}
@media (max-width: 768px) {
    .chart-table-body {
        padding: 15px 0px 0px;
    }
}

.chart-table-body td{
    font-size: 11px;
    text-align: center;
    padding-bottom: 5px;
}
@media (max-width: 768px) {
    .chart-table-body td{
        padding-bottom: 0px;
    }
}
.chart-table-body.sell,
.chart-table-body.sell a{
    color: var(--red-txt);
}
@media (max-width: 768px) {
    .chart-table-body.sell,
    .chart-table-body.sell a{
        color: var(--red-txt);
    }
}
.chart-table-body.buy,
.chart-table-body.buy a{
    color: var(--green-txt);
}

@media (max-width: 768px) {
    .chart-table-body.buy,
    .chart-table-body.buy a{
        color: var(--green-txt);
    }
}
.chart-table-body.buy.bg-move-buy{
    background-color: #03c7681f;
    animation: bgmovebuy 5s forwards;
    -webkit-animation: bgmovebuy 5s forwards; /* for less modern browsers */
    animation-delay: 1s;
}
@media (max-width: 768px) {
    .chart-table-body.buy.bg-move-buy{
        background-color: #03c7681f;
        animation: bgmovebuy 5s forwards;
        -webkit-animation: bgmovebuy 5s forwards; /* for less modern browsers */
        animation-delay: 1s;
    }
}
.chart-table-body.sell.bg-move-sell{
    background-color: #ff2b2b17;
    animation: bgmovesell 5s forwards;
    -webkit-animation: bgmovesell 5s forwards; /* for less modern browsers */
    animation-delay: 1s;
}
@media (max-width: 768px) {
    .chart-table-body.sell.bg-move-sell{
        background-color: #ff2b2b17;
        animation: bgmovesell 5s forwards;
        -webkit-animation: bgmovesell 5s forwards; /* for less modern browsers */
        animation-delay: 1s;
    }
}

/* @keyframes bgmovebuy {
    from {background-color: #03c7681f;}
    to {background-color: transparent;}
}
@keyframes bgmovesell {
    from {background-color: #ff2b2b17;}
    to {background-color: transparent;}
} */

.chart-table-body td:last-child{
    min-width: 45px;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.headers-info-btn.active span{
    color: var(--red);
}

@media screen and (max-width:992px) {
    .chart-bg {
        padding-top: 49px;
        padding-bottom: 20px;
        width: 100%;
        background: var(--home-bg);
        height: auto;
    }

    .token-info-container {
        padding: 20px 10px;
        border-radius: 4px;
        box-shadow: none;
        margin-bottom: 5px;
        margin-top: 25px;
        background: var(--white-darkgray);
    }

    .first-row {
        margin-bottom: 10px;
    }

    .second-row {
        display: flex; 
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .title-container {
        margin-right: 20px;
    }

    .price-movement {
        margin-right: 0px;
    }

    .token-img {
        height: 35px;
        margin-right: 10px;
        border-radius: 3px;
    }

    .token-title {
        font-size: 18px;
        max-width: 125px;
    }

    .token-price {
        font-size: 18px;
        max-width: 96px;
        color: var(--blue-white);
    }

    .movement {
        border-radius: 4px;
        padding: 1px 8px;
    }

    .movement span {
        font-size: 9px;
    }

    .token-address-tag {
        padding: 2px 6px;
        border-radius: 4px;
        margin-right: .2rem;
        background: var(--lightgray-darkgray);
        border: 1px solid var(--lightgray-darkgray);
    }

    .token-address-tag span {
        font-size: 9px;
        color: var(--address-tag);
        font-weight: 700;
    }

    .chart-btns {
        border-radius: 4px;
        min-width: 40px;
        margin-right: .2rem;
        background: var(--lightgray-darkgray);
    }
    @media (max-width: 768px) {
        .chart-btns {
            border-radius: 4px;
            min-width: 40px;
            margin-right: .2rem;
            background: var(--lightgray-darkgray);
        }
    }

    .chart-btns span {
        font-size: 9px;
        color: var(--blue-white);
        padding: 3px 3px;
        font-weight: 700;
    }
    @media (max-width: 768px) {
        .chart-btns span {
            font-size: 12px;
            color: var(--blue-white);
            padding: 3px 3px;
            font-weight: 700;
        }
    }

    .chtr-list {
        margin-top: 13px;
        margin-bottom: 13px;
    }
    @media (max-width: 768px) {
        .chtr-list {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    

    .chart-chtr {
        margin-right: 13px;
    }
    @media (max-width: 768px) {
        .chart-chtr {
            margin-right: 20px;
        }
    }

    .bsc-img {
        height: 17px;
    }

    .chart-chtr span {
        font-size: 9px;
        color: var(--blue-white);
    }
    @media (max-width: 768px) {
        .chart-chtr span {
            font-size: 12px;
            color: var(--blue-white);
        }
    }

    /*  chart grafic mobile */

    .chart-container {
        height: 527px;
        border-radius: 4px;
        margin-bottom: 25px;
        box-shadow: none;
        background: black;
    }

    .chart-info-container {
        height: 527px;
        padding: 0 10px;
        border-radius: 4px;
        box-shadow: none;
        margin-bottom: 25px;
        overflow-y: auto;
        background: rgb(0, 0, 0);
    }

    .chart-info-header {
        display: flex;
        border-bottom: 1px solid var(--gray);
        justify-content: space-around;
        
    }

    .headers-info-btn {
        border-radius: 4px;
        background: var(--lightgray-darkgray);
    }

    .headers-info-btn span {
        padding: 4px 13px;
        color: var(--blue-white);
    }

    .chart-table-header th {
        font-size: 9px;
        margin-right: 5px;
        color: var(--blue-white);
    }
    @media (max-width: 768px) {
        .chart-table-header th {
            font-size: 11px;
            margin-right: 5px;
            color: var(--blue-white);
        }
    }

    .chart-table-body td {
        font-size: 11px;
        margin-right: 5px;
        font-weight: 500;
        color: var(--blue-white);
    }
}
@media (max-width: 768px) {
    .chart-table-body td {
        font-size: 9px;
        margin-right: 5px;
        font-weight: 500;
        color: var(--blue-white);
    }
}

.chart-table-info th:nth-child(1),
.chart-table-info td:nth-child(1) {
  width: 10%;
}

.chart-table-info th:nth-child(2),
.chart-table-info td:nth-child(2) {
  width: 10%;
}

.chart-table-info th:nth-child(3),
.chart-table-info td:nth-child(3) {
  width: 10%;
}

.chart-table-info th:nth-child(4),
.chart-table-info td:nth-child(4) {
  width: 20%;
}
.chart-table-info th:nth-child(5),
.chart-table-info td:nth-child(5) {
  width: 5%;
}
.view-tx {
    text-decoration: none;
    color: var(--white);
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px; /* Adjust spacing between the View TX link and the previous text */
}


.custom-table th:nth-child(1),
.custom-table td:nth-child(1) {
    text-align: left;
    padding-left: 20px; /* Adjust this value to move the first column content by pixels. */
}

.custom-table th:nth-child(2),
.custom-table td:nth-child(2) {
    text-align: center;
    position: relative;
    left: -280px; /* Adjust this value to move the second column content by pixels. */
}

.custom-table th:nth-child(3),
.custom-table td:nth-child(3) {
    text-align: right;
    position: relative;
    left: -100px; /* Adjust this value to move the third column content by pixels. */
}


.custom-table th,
.custom-table td {
    padding: 5px 20px; /* Adjust these values to add more or less padding around the headers and data cells. */
}
.logo-container {
    position: relative !important;
    width: 60px !important;
    height: 70px !important;
    top: -10px;
    right: -20px;
  }
  
  .logo-image {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 80px !important;
    height: 80px !important;
    object-fit: contain !important;
  }
  .token-info-container h1 {
    color: #ff8c00;
    font-weight: bold;
    padding-left: 0px;
  }
  
  .token-contract {
    color: #286301; /* Change the color as desired */
    font-weight: bold;
  }
  .token-social-media {
    color: #633c01; /* Change the color as desired */
    font-weight: bold;
    position: absolute;
    left: 250px;
    top: 180px;
  }
  @media (max-width: 768px) {
    .token-social-media {
      position: relative;
      left: 0px;
      top: 0px;
    }
  }

  .latest-price {
    margin-left: 20px;
    /* Add more styles if needed */
  }
  @media (max-width: 768px) {
    .latest-price {
      margin-left: 10px;
    }
  }
  .latestDataPoint {
    color: #ff8c00;
    font-weight: bold;
    left: 10px;
  }
  
  .price {
    color: #ff8c00;
    position: relative;
    left: 250px;
    bottom: -40px;
    font-weight: bold;
  }
  
  @media (max-width: 767px) {
    .price {
      position: absolute; /* Change the positioning method */
      top: 65px; /* Adjust this value for mobile positioning */
      left: 235px; /* Adjust this value for mobile positioning */
    }
  }
  .token-social-media {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: -100px; /* Adjust this value as needed */
  }
  @media (max-width: 768px) {
    .token-social-media {
      margin-left: -240px;
    }
  }
