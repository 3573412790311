.featured-area {
    width: 100%;
  }
  
  .featured-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;
  }
  
  .featured-list-chart {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  
  .featured-list .featured-txt,
  .featured-list-chart .featured-txt {
    margin-bottom: 0;
    margin-right: 40px;
    color: var(--red);
    font-weight: 700;
    font-size: 15px;
  }
  
  .featured-list ul,
  .featured-list-chart ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-x: auto;
    white-space: nowrap;
  
    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .featured-list ul::-webkit-scrollbar,
  .featured-list-chart ul::-webkit-scrollbar {
    display: none;
  }
  
  @keyframes auto-scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  @keyframes auto-scroll-mobile {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-200%);
    }
  }
  
  .featured-list ul a,
  .featured-list-chart ul a {
    text-decoration: none;
  }
  
  .featured-list ul li {
    margin-right: 37px;
  }
  
  .featured-list-chart ul li {
    margin-right: 23px;
  }
  
  .featured-list ul a:last-child li,
  .featured-list-chart ul a:last-child li {
    margin-right: 0px;
  }
  
  .featured-txt-link {
    text-decoration: underline;
    color: var(--red);
    font-size: 14px;
    transition: color .2s;
  }
  
  .featured-txt-link:hover {
    color: var(--black);
  }
  
  ul li .text-black {
    color: var(--black-white);
    font-size: 14px;
    font-weight: 500;
  }
  
  ul li .text-black-chart {
    color: var(--black-white);
    font-size: 14px;
    font-weight: 500;
  }
  
  ul li .text-red {
    color: var(--red);
  }
  
  .featured-list ul:hover,
  .featured-list-chart ul:hover {
    animation-play-state: paused;
  }
  
  .featured-list ul a,
  .featured-list-chart ul a {
    display: inline-block;
  }
  
  /* Add these rules to target larger screens */
  @media screen and (min-width: 993px) {
    .featured-list ul:hover,
    .featured-list-chart ul:hover {
      animation-play-state: paused;
    }
  }
  
  @media screen and (max-width: 992px) {
    .featured-list {
      align-items: flex-start;
      margin-bottom: 40px;
    }
  
    .featured-list .featured-txt {
      margin-right: 25px;
      color: var(--blue-white);
      font-size: 10px;
      min-width: 73px;
      padding-top: 7px;
    }
  
    .featured-list ul,
    .featured-list-chart ul {
      align-items: center;
      justify-content: flex-start;
      overflow-x: auto;
      animation: auto-scroll-mobile 20s linear infinite;
    }

    .featured-list ul li {
        margin-right: 8px;
      }
    
      ul li .text-black {
        font-size: 10px;
        color: var(--blue-white);
      }
    
      .featured-list-chart ul li {
        display: flex;
        margin-right: 8px;
      }
    
      .featured-txt-link {
        min-width: 100px;
        margin-right: 10px;
        font-size: 10px;
        color: var(--blue-white);
        font-weight: 600;
      }
    
      ul li .text-black-chart {
        display: flex;
        color: var(--blue-white);
        font-size: 10px;
        margin-top: 10px;
      }
    
      .text-black-chart .text-red {
        margin-left: 3px;
      }
    
      /* Hide scrollbar for Chrome, Safari and Opera */
      .featured-list ul::-webkit-scrollbar,
      .featured-list-chart ul::-webkit-scrollbar {
        display: none;
      }
    
      /* Hide scrollbar for IE, Edge and Firefox */
      .featured-list ul,
      .featured-list-chart ul {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
