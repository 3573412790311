.header_btn {
    width: 120px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--headerbtn-bg);
    border: none;
    font-size: 13px;
    padding: 3px 12px 3px 10px;
    border-radius: 5px;
    font-weight: 600;
}

.partners-link a:first-child {
   margin-right: 15px;
}

.header_btn img{
    height: 18px;
    margin-right: 5px;
}

/* search btn */

.search_btn {
    border: none;
    font-size: 12px;
    color: var(--white);
    background:var(--red);
    padding: 9px 17px;
    font-weight: 500;
    border-radius: 5px;
    margin-left: 10px;
}

@media screen and (max-width:992px) {
    .search_btn {
        font-size: 11px;
        padding: 5px 13px;
        font-weight: 600;
        border-radius: 3px;
    }
}

