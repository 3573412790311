.chart-container {
  width: 100%;
  height: auto;
  max-height: 500px; /* You can adjust this value according to your needs */
}
  .chart-container canvas {
    width: 630px !important;
    height: 510px !important;
    position: relative;
    left: -0px;
  }
  .toolbar {
    display: flex;
    justify-content: flex-start; /* Align buttons horizontally */
    padding: 3.5px; /* Add some padding around the buttons */
  }
  
  .toolbar button {
    position: absolute;
    background-color: transparent; /* Set the background color to transparent */
    border: none; /* Remove the border */
    color: inherit; /* Inherit the font color from the parent */
    cursor: pointer; /* Change the cursor to a pointer on hover */
    outline: none; /* Remove the outline */
  }
  
  .fullscreen-button {
    top: -20px;
    right: 8px;
    color: rgb(255, 255, 255); /* Change the font color to white */
    text-shadow: 0 0 3px rgb(255, 255, 255); /* Add a subtle text shadow for more contrast */
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); /* Add a subtle box shadow around the button */
  }
  
  .snapshot-button {
    top: -20px;
    right: -14px;
  }

@media screen and (max-width: 274px) {
  .chart-container {
    padding-top: 49px;
    padding-bottom: 20px;
    width: 100%;
    background: var(--home-bg);
    height: auto;
}
}